import React from 'react'
import './Header.css'
 
import logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png'
import { Link } from 'react-scroll'
const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menueOpened, setmenueOpened] = React.useState(false)
  return (
    <div className="header">
      <img className='logo' src={logo} alt="" />
      {(menueOpened === false && mobile === true) ? (
        <div 
        style={{backgroundColor:'var(--appColor)', padding:"0.5rem", borderRadius:'5px'}}

        onClick={()=> setmenueOpened(true)}
        ><img src={Bars} alt="" style={{width: '1.5 rem', height: '1.5 rem'}}/></div>

      ) : <ul className='header-menue'>
        <li  >
          <link
          onClick={() =>setmenueOpened(false)}
          
          
          >Home</link>
           </li>
        <li onClick={() => setmenueOpened(false)}>
        <link>About us</link> </li>
        <li onClick={() => setmenueOpened(false)}><link>Programs</link> </li>
        <li onClick={() => setmenueOpened(false)}><link>why us</link> </li>
        <li  ><link
        onClick={() => setmenueOpened(false)}
         
        >Testimonials</link> </li>
      </ul>}


    </div>
  )
}

export default Header;
